<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap10Functions"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 10: Functions" image-name="functions.jpg" image-alt="Functions"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Why using functions can improve your code.</p></li>
<li><p>How to write a function.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Function signature, body, parameters, results</p></li>
<li><p>Return statement</p></li>
<li><p>Function call</p></li>
</ul>
<div id="what-is-a-function" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What is a function? <a href="#what-is-a-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="definition" class="anchor"></div>
<h2 data-number="3.1"><span class="header-section-number">3.1</span> Definition <a href="#definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A function is a block of code invoked by a specific name. It may receive input values. It may also output values.</p>
<div id="vocabulary" class="anchor"></div>
<h2 data-number="3.2"><span class="header-section-number">3.2</span> Vocabulary <a href="#vocabulary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s introduce important terms :</p>
<ul>
<li><p>When we create a function, we say that we <strong>define</strong> it.</p></li>
<li><p>The input values are the function <strong>parameters.</strong></p></li>
<li><p>The output values are the function <strong>results.</strong></p></li>
<li><p>When we use a function, we say that we <strong>call</strong> it.</p></li>
<li><p>A function usage is called a <strong>function call</strong>.</p></li>
</ul>
<div id="example" class="anchor"></div>
<h2 data-number="3.3"><span class="header-section-number">3.3</span> Example <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Here is an example function taken from the standard library.</p>
<pre v-highlightjs><code class="go" v-pre >// TrimSpace returns a slice of the string s, with all leading
// and trailing white space removed, as defined by Unicode.
func TrimSpace(s string) string {
   // Fast path for ASCII: look for the first ASCII non-space byte
   start := 0
   for ; start &lt; len(s); start++ {
      c := s[start]
      if c &gt;= utf8.RuneSelf {
         // If we run into a non-ASCII byte, fall back to the
         // slower Unicode-aware method on the remaining bytes
         return TrimFunc(s[start:], unicode.IsSpace)
      }
      if asciiSpace[c] == 0 {
         break
      }
   }

   // Now look for the first ASCII non-space byte from the end
   stop := len(s)
   for ; stop &gt; start; stop-- {
      c := s[stop-1]
      if c &gt;= utf8.RuneSelf {
         return TrimFunc(s[start:stop], unicode.IsSpace)
      }
      if asciiSpace[c] == 0 {
         break
      }
   }

   // At this point s[start:stop] starts and ends with an ASCII
   // non-space bytes, so we&#39;re done. Non-ASCII cases have already
   // been handled above.
   return s[start:stop]
}</code></pre>
<p>We will detail the structure of the function in the next sections.</p>
<div id="why-do-we-need-them" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Why do we need them? <a href="#why-do-we-need-them"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="reusability" class="anchor"></div>
<h2 data-number="4.1"><span class="header-section-number">4.1</span> Reusability <a href="#reusability"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>With functions, you can write a block of complex code once and then use it several times in your application.</p>
<div id="use-code-from-other-developers" class="anchor"></div>
<h2 data-number="4.2"><span class="header-section-number">4.2</span> Use code from other developers <a href="#use-code-from-other-developers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In your go program, you can use functions written by other programmers :</p>
<ol type="1">
<li><p>From the Go standard library</p></li>
<li><p>From projects published by Go developers</p></li>
</ol>
<p>We will see how to do both in the next sections.</p>
<div id="abstraction" class="anchor"></div>
<h2 data-number="4.3"><span class="header-section-number">4.3</span> Abstraction <a href="#abstraction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This is what we did in the previous blocks of code we wrote when we called the function <span v-highlightjs><code class="go" v-pre style="display: inline">Println</code></span> from the <span v-highlightjs><code class="go" v-pre style="display: inline">fmt</code></span> package. When you call <span v-highlightjs><code class="go" v-pre style="display: inline">Println</code></span>, you call a massive block of code. This block of code is not visible to the caller of the function. It’s hidden. And this is a great thing! We do not need to know the function internals to use it. Functions bring a level of abstraction.</p>
<p>Functions are like black boxes; implementation details are hidden from the caller.</p>
<div id="parameters-and-results" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Parameters and results <a href="#parameters-and-results"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A function can be seen as a machine; it takes input, performs an action And it outputs something.</p>
<div id="number-of-parameters-number-of-results" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Number of parameters, number of results <a href="#number-of-parameters-number-of-results"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>The number of <strong>parameters</strong> may vary from 0 to N. Technically speaking, N can be 100 or even 1000.</p></li>
<li><p>It’s not a good practice to define a lot of parameters.</p>
<ul>
<li>In my opinion, a function should not have more than three parameters.</li>
</ul></li>
<li><p>The number of <strong>results</strong> may also vary from 0 to N.</p>
<ul>
<li>My recommendation is to limit yourself to 2 results.</li>
</ul></li>
</ul>
<div id="return-statement" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Return statement <a href="#return-statement"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A return statement begins with the keyword <span v-highlightjs><code class="go" v-pre style="display: inline">return</code></span>. The return statement is used <strong>in a function</strong> to :</p>
<ol type="1">
<li><p><strong>Terminate the execution</strong> of the function</p></li>
<li><p><strong>Optionally</strong> provide the results of the function</p></li>
</ol>
<p>We will see in concrete examples how to use the return statement.</p>
<div id="a-standard-function-2-parameters-one-result" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> A standard function: 2 parameters, one result <a href="#a-standard-function-2-parameters-one-result"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<figure>
<b-img :src="require('@/assets/images/function_2_param_1_result.png')" alt="Syntax : function 2 parameters 1 result"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Syntax : function 2 parameters 1 result</figcaption>
</figure>
<p>The function is composed of :</p>
<ol type="1">
<li><p>A name (which is an identifier)</p></li>
<li><p>A list of parameters enclosed by braces.</p></li>
<li><p>Each parameter has a name and a type.</p></li>
<li><p>After the parameters list, you find the result list</p></li>
</ol>
<div id="declaration-example" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Declaration example : <a href="#declaration-example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// functions/declaration-example/main.go
package main

// compute the price of a room based on its rate per night
// and the number of night
func computePrice(rate float32, nights int) float32 {
    return rate * float32(nights)
}</code></pre>
<p>Let’s detail our function :</p>
<ul>
<li><p>The name of the function is <span v-highlightjs><code class="go" v-pre style="display: inline">computePrice</code></span></p></li>
<li><p>The signature is <span v-highlightjs><code class="go" v-pre style="display: inline">(rate float32, nights int) float32</code></span></p></li>
<li><p>The first parameter is named <span v-highlightjs><code class="go" v-pre style="display: inline">rate</code></span> and is of type <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span>.</p></li>
<li><p>The second parameter is named <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> and is an integer.</p></li>
<li><p>There is one result, its type is <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span></p></li>
<li><p>The function body is <span v-highlightjs><code class="go" v-pre style="display: inline">return rate * float32(nights)</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">return</code></span> is a keyword: it indicates to Go that what follows is our result.</p>
<ul>
<li>The result is a multiplication between <span v-highlightjs><code class="go" v-pre style="display: inline">rate</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">float32(nights)</code></span></li>
</ul></li>
<li><p><strong>What is the function purpose?</strong></p>
<ul>
<li><p>The comment before the function explains what the function does</p></li>
<li><p>The name gives us an indication, too; it computes a price.</p></li>
<li><p>The signature also gives information</p>
<ul>
<li><p>It takes a rate and a number of nights</p></li>
<li><p>It returns a float32, the price.</p></li>
</ul></li>
</ul></li>
</ul>
<div id="usage-example" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> Usage example : <a href="#usage-example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// functions/usage-example/main.go
package main

import &quot;fmt&quot;

func main() {
    johnPrice := computePrice(145.90, 3) //*\label{funcEx1Us1}
    paulPrice := computePrice(26.32, 10) //*\label{funcEx1Us2}
    robPrice := computePrice(189.21, 2)  //*\label{funcEx1Us3}

    total := johnPrice + paulPrice + robPrice
    fmt.Printf(&quot;TOTAL : %0.2f $&quot;, total)
}

func computePrice(rate float32, nights int) float32 { //*\label{funcEx1Dec}
    return rate * float32(nights)
}</code></pre>
<p>The result of <span v-highlightjs><code class="go" v-pre style="display: inline">computePrice(145.90, 3)</code></span> is stored into the variable <span v-highlightjs><code class="go" v-pre style="display: inline">johnPrice</code></span>.</p>
<p>The result of <span v-highlightjs><code class="go" v-pre style="display: inline">computePrice(26.32, 10)</code></span> is stored into the variable <span v-highlightjs><code class="go" v-pre style="display: inline">paulPrice</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">omputePrice(189.21, 2)</code></span> into <span v-highlightjs><code class="go" v-pre style="display: inline">robPrice</code></span>.</p>
<p>We then create a variable <span v-highlightjs><code class="go" v-pre style="display: inline">total</code></span> and assign to it the sum of <span v-highlightjs><code class="go" v-pre style="display: inline">johnPrice</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">paulPrice</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">robPrice</code></span>.</p>
<div id="quick-questions" class="anchor"></div>
<h4 data-number="8.2.0.1"><span class="header-section-number">8.2.0.1</span> Quick questions <a href="#quick-questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ol type="1">
<li><p>What is the type of <span v-highlightjs><code class="go" v-pre style="display: inline">johnPrice</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">paulPrice</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">total</code></span> ?</p></li>
<li><p>In the function, why do we need to write <span v-highlightjs><code class="go" v-pre style="display: inline">float32(nights)</code></span>, why not just write <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> ?</p></li>
<li><p>Can we write <span v-highlightjs><code class="go" v-pre style="display: inline">computePrice(189.21, "two")</code></span> ?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h4 data-number="8.2.0.2"><span class="header-section-number">8.2.0.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span>. The function returns a float32; we assign to <span v-highlightjs><code class="go" v-pre style="display: inline">johnPrice</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">paulPrice</code></span> the return value of this function. As a consequence, they are float32. Concerning <span v-highlightjs><code class="go" v-pre style="display: inline">total</code></span>, the addition of 3 <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span> value is a <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span>.</p>
<ol type="1">
<li><p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> is an integer. We need to convert <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> to float32 to multiply it by a <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span>. When we multiply two values, they should have the same type.</p></li>
<li><p>The second parameter of the function computePrice is an integer.<span v-highlightjs><code class="go" v-pre style="display: inline">"two"</code></span> is a string. You give the function a parameter with the wrong type.</p></li>
</ol>
<div id="scope-of-a-function" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Scope of a function <a href="#scope-of-a-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Each function has a scope. A function’s scope begins with the opening curly brace <span v-highlightjs><code class="go" v-pre style="display: inline">{</code></span> and stops with the closing curly brace <span v-highlightjs><code class="go" v-pre style="display: inline">}</code></span>. A scope is a portion of the source code. This is an extent of the source code.</p>
<p>Inside this block, the parameters of the function are defined. We did not need to initialize and assign any variable : <span v-highlightjs><code class="go" v-pre style="display: inline">rate</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> can be used freely :</p>
<pre v-highlightjs><code class="go" v-pre >func computePrice(rate float32, nights int) float32 {
   return rate * float32(nights)
}</code></pre>
<p>They can be used only in the scope of the function. After the closing curly brace, they do not exist. The parameters only exist in the scope of the function.</p>
<pre v-highlightjs><code class="go" v-pre >// functions/scope/main.go
package main

import &quot;fmt&quot;

func main() {
    johnPrice := computePrice(145.90, 3)
    fmt.Println(&quot;John:&quot;, johnPrice, &quot;rate:&quot;, rate)
}

func computePrice(rate float32, nights int) float32 {
    return rate * float32(nights)
}</code></pre>
<p>Does the previous code compile? Well, it does not, we try to print the variable <span v-highlightjs><code class="go" v-pre style="display: inline">rate</code></span> which do not exists in the scope of the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function. But <span v-highlightjs><code class="go" v-pre style="display: inline">rate</code></span> exists in the scope of the <span v-highlightjs><code class="go" v-pre style="display: inline">computePrice</code></span> function.</p>
<p>If we try to compile the previous program, we got this error :</p>
<pre v-highlightjs><code class="go" v-pre >./main.go:7:43: undefined: rate</code></pre>
<figure>
<b-img :src="require('@/assets/images/scope_hightlight.png')" alt="Syntax : function 2 parameters 1 named result"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Syntax : function 2 parameters 1 named result</figcaption>
</figure>
<p>The scope is a limitation that allows a perfect encapsulation of the function. From the outside, you cannot manipulate the inside. The scope is like the metallic shield of a machine. The user cannot manipulate the internals of the machine.</p>
<p>Of course, you can also define new variables inside the function :</p>
<pre v-highlightjs><code class="go" v-pre >// functions/scope2/main.go
package main

import &quot;fmt&quot;

func main() {
    fmt.Println(computePrice(145.90, 3))
}

func computePrice(rate float32, nights int) float32 {
    n := float32(nights)
    return rate * n
}</code></pre>
<p>We created a new variable <span v-highlightjs><code class="go" v-pre style="display: inline">n</code></span>. Which is initialized with the value <span v-highlightjs><code class="go" v-pre style="display: inline">float32(nights)</code></span>. This variable exists only on the scope of the <span v-highlightjs><code class="go" v-pre style="display: inline">computePrice</code></span> function.</p>
<div id="parameters-1-named-result" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> 2 parameters, 1 named result <a href="#parameters-1-named-result"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<figure>
<b-img :src="require('@/assets/images/function_2_param_1_named_result.png')" alt="Syntax : function 2 parameters 1 named result"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Syntax : function 2 parameters 1 named result</figcaption>
</figure>
<p>Go gives you the ability to give a name to results. In the previous section, we had an anonymous result. We knew only the type. Here we specify its name and its type.</p>
<p>Parenthesis that surround the result and the result type are mandatory.</p>
<div id="declaration-example-1" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> Declaration example : <a href="#declaration-example-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >func computePrice(rate float32, nights int) (price float32) {
   price = rate * float32(nights)
   return
}</code></pre>
<ul>
<li><p>In the function body, we assign to price the value <span v-highlightjs><code class="go" v-pre style="display: inline">rate * float32(nights)</code></span></p></li>
<li><p>Note that <strong>the variable price exists already; we do not need to declare it</strong></p></li>
<li><p>The variable price is <strong>initialized to the zero value of it’s type</strong> (here float32 =&gt; 0)</p></li>
<li><p>Note also that we simply call <span v-highlightjs><code class="go" v-pre style="display: inline">return</code></span>. We could have written <span v-highlightjs><code class="go" v-pre style="display: inline">return price</code></span>, but it’s not necessary when you name parameters.</p></li>
<li><p>When return is called, the function will return the current value of price</p></li>
</ul>
<div id="usage-example-1" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> Usage example : <a href="#usage-example-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Usage syntax is still the same :</p>
<pre v-highlightjs><code class="go" v-pre >johnPrice := computePrice(145.90, 3)</code></pre>
<div id="quick-question" class="anchor"></div>
<h4 data-number="10.2.0.1"><span class="header-section-number">10.2.0.1</span> Quick question <a href="#quick-question"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// functions/usage-example-2/main.go
package main

import &quot;fmt&quot;

func main() {
    johnPrice := computePrice(145.90, 3)
    fmt.Printf(&quot;TOTAL : %0.2f $&quot;, johnPrice)
}

// compute the price with a 200% margin
func computePrice(rate float32, nights int) (price float32) {
    p := rate * float32(nights)
    p = p * 2
    return
}</code></pre>
<ol type="1">
<li><p>Does this code compile?</p></li>
<li><p>What is the output of this program</p></li>
</ol>
<div id="answers-1" class="anchor"></div>
<h4 data-number="10.2.0.2"><span class="header-section-number">10.2.0.2</span> Answers <a href="#answers-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ol type="1">
<li><p>Yes</p></li>
<li><p><strong>TOTAL : 0.00 $</strong></p></li>
</ol>
<p>Why? The function <span v-highlightjs><code class="go" v-pre style="display: inline">computePrice</code></span> has a named result <span v-highlightjs><code class="go" v-pre style="display: inline">price</code></span>. In the function body, we never assign to <span v-highlightjs><code class="go" v-pre style="display: inline">price</code></span> the value expected. When <span v-highlightjs><code class="go" v-pre style="display: inline">return</code></span> is called the value of price is returned. The value of price is automatically initialized with the zero value of <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span> which is <span v-highlightjs><code class="go" v-pre style="display: inline">0.00</code></span>. The function returns <span v-highlightjs><code class="go" v-pre style="display: inline">0.00</code></span>.</p>
<div id="parameter-1-result" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> 0 parameter, 1 result <a href="#parameter-1-result"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<figure>
<b-img :src="require('@/assets/images/function_0_param_1_result.png')" alt="Syntax : function 0 parameter 1 result"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Syntax : function 0 parameter 1 result</figcaption>
</figure>
<p>Here we have 0 parameters; the function takes no input. But it returns something.</p>
<div id="example-1" class="anchor"></div>
<h2 data-number="11.1"><span class="header-section-number">11.1</span> Example <a href="#example-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// functions/usage-example-3/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {

    vacant := vacantRooms()
    fmt.Println(&quot;Vacant rooms:&quot;, vacant)

}

func vacantRooms() int {
    rand.Seed(time.Now().UTC().UnixNano())
    return rand.Intn(100)
}</code></pre>
<p>We define the functions: <span v-highlightjs><code class="go" v-pre style="display: inline">vacantRooms</code></span>.</p>
<p>This function is called in the main function. The value returned is stored in a variable named vacant.</p>
<div id="quick-question-1" class="anchor"></div>
<h4 data-number="11.1.0.1"><span class="header-section-number">11.1.0.1</span> Quick question <a href="#quick-question-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ol type="1">
<li>Is the variable vacant necessary?</li>
</ol>
<div id="answer" class="anchor"></div>
<h4 data-number="11.1.0.2"><span class="header-section-number">11.1.0.2</span> Answer <a href="#answer"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>No. The variable is declared but used only once. It is not necessary. We can write directly :</p>
<pre v-highlightjs><code class="go" v-pre >func main() {
   fmt.Println(&quot;Vacant rooms:&quot;, vacantRooms())
}</code></pre>
<p>Declaring a variable makes sense when you need to store the function’s result to use it at another location in your program.</p>
<div id="parameter-0-result" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> 0 parameter, 0 result <a href="#parameter-0-result"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<figure>
<b-img :src="require('@/assets/images/function_0_param_0_result.png')" alt="Syntax : function 0 parameter 0 result"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Syntax : function 0 parameter 0 result</figcaption>
</figure>
<div id="example-2" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Example <a href="#example-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// functions/usage-example-4/main.go
package main

import (
    &quot;fmt&quot;
)

func main() {
    printHeader()
}

func printHeader() {
    fmt.Println(&quot;Hotel Golang&quot;)
    fmt.Println(&quot;San Francisco, CA&quot;)
}</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">printHeader</code></span> will print <span v-highlightjs><code class="go" v-pre style="display: inline">Hotel Golang</code></span> and then on a second line, it will print <span v-highlightjs><code class="go" v-pre style="display: inline">San Francisco, CA</code></span>. In the main function (which also takes 0 parameters and have 0 results) we call <span v-highlightjs><code class="go" v-pre style="display: inline">printHeader()</code></span>.</p>
<div id="quick-questions-1" class="anchor"></div>
<h4 data-number="12.1.0.1"><span class="header-section-number">12.1.0.1</span> Quick questions <a href="#quick-questions-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ol type="1">
<li><p>Can we add a return statement to the function <span v-highlightjs><code class="go" v-pre style="display: inline">printHeader</code></span> ?</p></li>
<li><p>The following code defines a constant named <span v-highlightjs><code class="go" v-pre style="display: inline">HotelName</code></span>. The constant is used inside the <span v-highlightjs><code class="go" v-pre style="display: inline">printHeader</code></span> function. What do you think of this code?</p></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// functions/quick-question/main.go
package main

import (
    &quot;fmt&quot;
)

func main() {
    const HotelName = &quot;Golang&quot;
    printHeader()
}

func printHeader() {
    fmt.Println(&quot;Hotel&quot;, HotelName)
    fmt.Println(&quot;San Francisco, CA&quot;)
}</code></pre>
<div id="answers-2" class="anchor"></div>
<h4 data-number="12.1.0.2"><span class="header-section-number">12.1.0.2</span> Answers <a href="#answers-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ol type="1">
<li><p>The function has no result. Adding a return statement to the function is <strong>optional</strong>. When the program reaches the end of the function, it will terminate it’s execution.</p></li>
<li><p>The constant <span v-highlightjs><code class="go" v-pre style="display: inline">HotelName</code></span> is defined in the scope of the main function. This constant does not exist in the scope of the function <span v-highlightjs><code class="go" v-pre style="display: inline">printHeader</code></span>. The program will not compile!</p></li>
</ol>
<div id="the-main-function" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> The main function <a href="#the-main-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The first function that we used was the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function. The <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function (from the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> package) has no parameters and no result. This is the <u>entry point of a program</u>.</p>
<pre v-highlightjs><code class="go" v-pre >package main

func main() {

}</code></pre>
<div id="practical-application" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Practical Application <a href="#practical-application"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="mission" class="anchor"></div>
<h2 data-number="14.1"><span class="header-section-number">14.1</span> Mission <a href="#mission"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We will continue to develop the management application of the hotel. In the figure <a href="#fig:Wireframesappmngt-function" data-reference-type="ref" data-reference="fig:Wireframesappmngt-function">1</a> you can see the wireframes of the application :</p>
<figure>
<b-img :src="require('@/assets/images/application_control_flow.png')" alt="Wireframes of the hotel management application[fig:Wireframesappmngt-function]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Wireframes of the hotel management application<span id="fig:Wireframesappmngt-function" label="fig:Wireframesappmngt-function">[fig:Wireframesappmngt-function]</span></figcaption>
</figure>
<p>Take a look at the following code :</p>
<pre v-highlightjs><code class="go" v-pre >package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    const hotelName = &quot;Gopher Paris Inn&quot;
    const totalRooms = 134
    const firstRoomNumber = 110

    rand.Seed(time.Now().UTC().UnixNano())
    roomsOccupied := rand.Intn(totalRooms)
    roomsAvailable := totalRooms - roomsOccupied

    occupancyRate := (float32(roomsOccupied) / float32(totalRooms)) * 100
    var occupancyLevel string
    if occupancyRate &gt; 70 {
        occupancyLevel = &quot;High&quot;
    } else if occupancyRate &gt; 20 {
        occupancyLevel = &quot;Medium&quot;
    } else {
        occupancyLevel = &quot;Low&quot;
    }

    fmt.Println(&quot;Hotel:&quot;, hotelName)
    fmt.Println(&quot;Number of rooms&quot;, totalRooms)
    fmt.Println(&quot;Rooms available&quot;, roomsAvailable)
    fmt.Println(&quot;                  Occupancy Level:&quot;, occupancyLevel)
    fmt.Printf(&quot;                  Occupancy Rate: %0.2f %%\n&quot;, occupancyRate)

    if roomsAvailable &gt; 0 {
        fmt.Println(&quot;Rooms:&quot;)
        for i := 0; roomsAvailable &gt; i; i++ {
            roomNumber := firstRoomNumber + i
            size := rand.Intn(6) + 1
            nights := rand.Intn(10) + 1
            fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot; nights &quot;)
        }
    } else {
        fmt.Println(&quot;No rooms available for tonight&quot;)
    }

}</code></pre>
<p>Refactor this code and create functions that will :</p>
<ul>
<li><p>Compute the occupancy level</p></li>
<li><p>Compute the occupancy rate</p></li>
<li><p>Print the details of a specific room.</p></li>
</ul>
<div id="solution" class="anchor"></div>
<h2 data-number="14.2"><span class="header-section-number">14.2</span> Solution <a href="#solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="the-three-new-functions" class="anchor"></div>
<h3 data-number="14.2.1"><span class="header-section-number">14.2.1</span> The three new functions <a href="#the-three-new-functions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >//....

// display information about a room
func printRoomDetails(roomNumber, size, nights int) {
   fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot; nights &quot;)
}

// retrieve occupancyLevel from an occupancyRate
// From 0% to 30% occupancy rate return Low
// From 30% to 60% occupancy rate return Medium
// From 60% to 100% occupancy rate return High
func occupancyLevel(occupancyRate float32) string {
   if occupancyRate &gt; 70 {
      return &quot;High&quot;
   } else if occupancyRate &gt; 20 {
      return &quot;Medium&quot;
   } else {
      return &quot;Low&quot;
   }
}

// compute the hotel occupancy rate
// return a percentage
// ex : 14,43 =&gt; 14,43%
func occupancyRate(roomsOccupied int, totalRooms int) float32 {
   return (float32(roomsOccupied) / float32(totalRooms)) * 100
}</code></pre>
<div id="the-main-function-program-entry-point" class="anchor"></div>
<h3 data-number="14.2.2"><span class="header-section-number">14.2.2</span> The main function (program entry point) <a href="#the-main-function-program-entry-point"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >package main

import (
   &quot;fmt&quot;
   &quot;math/rand&quot;
   &quot;time&quot;
)

func main() {
   const hotelName = &quot;Gopher Paris Inn&quot;
   const totalRooms = 134
   const firstRoomNumber = 110

   rand.Seed(time.Now().UTC().UnixNano())
   roomsOccupied := rand.Intn(totalRooms)
   roomsAvailable := totalRooms - roomsOccupied

   occupancyRate := occupancyRate(roomsOccupied, totalRooms)
   occupancyLevel := occupancyLevel(occupancyRate)

   fmt.Println(&quot;Hotel:&quot;, hotelName)
   fmt.Println(&quot;Number of rooms&quot;, totalRooms)
   fmt.Println(&quot;Rooms available&quot;, roomsAvailable)
   fmt.Println(&quot;                  Occupancy Level:&quot;, occupancyLevel)
   fmt.Printf(&quot;                  Occupancy Rate: %0.2f %%\n&quot;, occupancyRate)

   if roomsAvailable &gt; 0 {
      fmt.Println(&quot;Rooms:&quot;)
      for i := 0; roomsAvailable &gt; i; i++ {
         roomNumber := firstRoomNumber + i
         size := rand.Intn(6) + 1
         nights := rand.Intn(10) + 1
         printRoomDetails(roomNumber, size, nights)
      }
   } else {
      fmt.Println(&quot;No rooms available for tonight&quot;)
   }

}

// ... 3 functions definition (cf. supra)</code></pre>
<div id="explanations" class="anchor"></div>
<h2 data-number="14.3"><span class="header-section-number">14.3</span> Explanations <a href="#explanations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s detail the construction of each function :</p>
<div id="printroomdetails" class="anchor"></div>
<h3 data-number="14.3.1"><span class="header-section-number">14.3.1</span> printRoomDetails <a href="#printroomdetails"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// display information about a room
func printRoomDetails(roomNumber, size, nights int) {
   fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot; nights &quot;)
}</code></pre>
<p>This function takes three parameters: <span v-highlightjs><code class="go" v-pre style="display: inline">roomNumber</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">size</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span>. Those three parameters are of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>. The function returns nothing.</p>
<p>When the parameters have the same, we can write :</p>
<pre v-highlightjs><code class="go" v-pre >func printRoomDetails(roomNumber, size, nights int)</code></pre>
<p>instead of :</p>
<pre v-highlightjs><code class="go" v-pre >func printRoomDetails(roomNumber int, size int, nights int)</code></pre>
<p>This last notation is perfectly legal, but it requires more characters and more understanding effort from the reader.</p>
<p>The <strong>body</strong> of the function is a call to <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println</code></span>. We print :</p>
<ul>
<li><p>the value of <span v-highlightjs><code class="go" v-pre style="display: inline">roomNumber</code></span></p></li>
<li><p>the string <span v-highlightjs><code class="go" v-pre style="display: inline">":"</code></span></p></li>
<li><p>the value of <span v-highlightjs><code class="go" v-pre style="display: inline">size</code></span></p></li>
<li><p>the string <span v-highlightjs><code class="go" v-pre style="display: inline">"people /"</code></span></p></li>
<li><p>the value of <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span></p></li>
<li><p>the string <span v-highlightjs><code class="go" v-pre style="display: inline">" nights "</code></span></p></li>
</ul>
<p>When the value of <span v-highlightjs><code class="go" v-pre style="display: inline">roomNumber</code></span> is 169, the value of <span v-highlightjs><code class="go" v-pre style="display: inline">size</code></span> is four, and the value of <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> is 5: the function will print :</p>
<pre v-highlightjs><code class="go" v-pre >169 : 4 people / 5  nights</code></pre>
<p>When the value of nights is equal to 1 (and the other variables have the same value) the output result is :</p>
<pre v-highlightjs><code class="go" v-pre >169 : 4 people / 1  nights</code></pre>
<p>It’s a bug! We could have designed our program to output “night” without the “s” if the number of nights is equal to 1.</p>
<div id="challenge-plural-vs.-singular" class="anchor"></div>
<h4 data-number="14.3.1.1"><span class="header-section-number">14.3.1.1</span> Challenge: plural vs. singular <a href="#challenge-plural-vs.-singular"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ol type="1">
<li><p>Write a second version of the function <span v-highlightjs><code class="go" v-pre style="display: inline">printRoomDetails</code></span> that corrects the plural singular bug.</p></li>
<li><p>The input parameter types are questionable. Why?</p></li>
</ol>
<div id="occupancylevel" class="anchor"></div>
<h3 data-number="14.3.2"><span class="header-section-number">14.3.2</span> occupancyLevel <a href="#occupancylevel"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// retrieve occupancyLevel from an occupancyRate
// From 0% to 30% occupancy rate return Low
// From 30% to 60% occupancy rate return Medium
// From 60% to 100% occupancy rate return High
func occupancyLevel(occupancyRate float32) string {
   if occupancyRate &gt; 70 {
      return &quot;High&quot;
   } else if occupancyRate &gt; 20 {
      return &quot;Medium&quot;
   } else {
      return &quot;Low&quot;
   }
}</code></pre>
<p>Here the function takes in input a <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span> parameter. The name of this parameter is <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyRate</code></span>. The function returns a <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>.</p>
<p>We have an if / else if / else construct. It is adapted in this case where we have three possible outcomes.</p>
<div id="challenge-give-me-your-better-switch-case" class="anchor"></div>
<h4 data-number="14.3.2.1"><span class="header-section-number">14.3.2.1</span> Challenge: give me your better switch case <a href="#challenge-give-me-your-better-switch-case"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Write a second version of the function <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLevel</code></span> which use a switch/case instead of an <strong>if / else if / else</strong>structure.</p>
<div id="occupancyrate" class="anchor"></div>
<h3 data-number="14.3.3"><span class="header-section-number">14.3.3</span> occupancyRate <a href="#occupancyrate"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// compute the hotel occupancy rate
// return a percentage
// ex : 14,43 =&gt; 14,43%
func occupancyRate(roomsOccupied int, totalRooms int) float32 {
   return (float32(roomsOccupied) / float32(totalRooms)) * 100
}</code></pre>
<p>We take two integers as input. Because the two parameters have the same type, we could have written :</p>
<pre v-highlightjs><code class="go" v-pre >func occupancyRate(roomsOccupied, totalRooms int) float32</code></pre>
<p>The function body is composed of a return statement. The result of the computation <span v-highlightjs><code class="go" v-pre style="display: inline">(float32(roomsOccupied) / float32(totalRooms)) * 100</code></span> is returned.</p>
<div id="about-float32x" class="anchor"></div>
<h4 data-number="14.3.3.1"><span class="header-section-number">14.3.3.1</span> About float32(X) <a href="#about-float32x"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">float32(X)</code></span> will convert the variable <span v-highlightjs><code class="go" v-pre style="display: inline">X</code></span> to a float32. We can convert only numeric types to float32 :</p>
<pre v-highlightjs><code class="go" v-pre >// try to convert a string to a float32!
float32(&quot;test&quot;)</code></pre>
<p>It Will not compile! (the error message is <strong>cannot convert test (type untyped string) to type float32</strong>)</p>
<div id="challenges-solutions" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Challenges solutions <a href="#challenges-solutions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="plural-vs.-singular" class="anchor"></div>
<h2 data-number="15.1"><span class="header-section-number">15.1</span> Plural vs. singular <a href="#plural-vs.-singular"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To detect if a quantity is singular, you have to compare it with 1. If the quantity is 1, then your word will be singular.</p>
<div id="first-solution" class="anchor"></div>
<h3 data-number="15.1.1"><span class="header-section-number">15.1.1</span> First solution <a href="#first-solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// functions/plural-singular-sol-1/main.go
package main

import &quot;fmt&quot;

func main() {
    printRoomDetails(112, 2, 2)
}

// display information about a room
func printRoomDetails(roomNumber, size, nights int) {
    nightText := &quot;nights&quot;
    if nights == 1 {
        nightText = &quot;night&quot;
    }
    fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, nightText)
}</code></pre>
<p>Here we define a variable <span v-highlightjs><code class="go" v-pre style="display: inline">nightText</code></span>. We initialize it with the value <span v-highlightjs><code class="go" v-pre style="display: inline">"nights"</code></span>. Then we add an if. If nights is equal to 1, then the value of the variable <span v-highlightjs><code class="go" v-pre style="display: inline">nightText</code></span> is overwritten to <span v-highlightjs><code class="go" v-pre style="display: inline">"night"</code></span></p>
<p>Then the variable <span v-highlightjs><code class="go" v-pre style="display: inline">nightText</code></span> is used as a parameter of <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println</code></span>.</p>
<div id="the-second-solution-not-the-best" class="anchor"></div>
<h3 data-number="15.1.2"><span class="header-section-number">15.1.2</span> The second solution (not the best) <a href="#the-second-solution-not-the-best"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// functions/plural-singular-sol-2/main.go
package main

import &quot;fmt&quot;

func main() {
    printRoomDetails2(112, 3, 4)
}

// display information about a room
// This alternative works, but the code is duplicated...
func printRoomDetails2(roomNumber, size, nights int) {
    if nights == 1 {
        fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot;night&quot;)
    } else {
        // practically the same line as before!
        // avoid this
        fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot;nights&quot;)
    }
}</code></pre>
<p>Here we do not define any variable. We have instead an if / else construct. This code will work. But it’s not optimal :</p>
<ul>
<li><p>The if construct is easier to read than the if/else construct. In the if-else construct, the reader of your code will need to think about the opposite of being equal to 1.</p></li>
<li><p>We have code duplication. The call to fmt.Println is repeated, and the two calls are practically the same.</p></li>
<li><p>What if <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> is equal to <span v-highlightjs><code class="go" v-pre style="display: inline">0</code></span> ?</p></li>
</ul>
<div id="about-the-input-parameters-type" class="anchor"></div>
<h3 data-number="15.1.3"><span class="header-section-number">15.1.3</span> About the input parameters type : <a href="#about-the-input-parameters-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>We could replace the int with an unsigned integer. roomNumber, size, nights are always positive... If we keep the int type, we could have a negative number of nights, which is impossible and may cause some serious bugs in the system. We could generate negative prices : <span class="math inline">-3\text{ nights}\times30\$=-90\$</span> ! We will give money to the customer to stay in our hotel!</p>
<div id="give-me-your-better-switch-case" class="anchor"></div>
<h2 data-number="15.2"><span class="header-section-number">15.2</span> Give me your better switch case <a href="#give-me-your-better-switch-case"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you have an if / else if / else, you can replace it with a switch case.</p>
<pre v-highlightjs><code class="go" v-pre >func occupancyLevel(occupancyRate float32) string {
   switch {
   case occupancyRate &gt; 70:
      return &quot;High&quot;
   case occupancyRate &gt; 20:
      return &quot;Medium&quot;
   default:
      return &quot;Low&quot;

   }
}</code></pre>
<p>Here no expression is provided in the switch case header. This is the equivalent of :</p>
<pre v-highlightjs><code class="go" v-pre >switch true{
   case occupancyRate &gt; 70:
   //..
   }</code></pre>
<p>The program will :</p>
<ol type="1">
<li><p>Evaluate the boolean expression <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyRate &gt; 70</code></span>. If it’s equal to <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span>, then it will return the string <span v-highlightjs><code class="go" v-pre style="display: inline">"High"</code></span>. If not, the next step is executed.</p></li>
<li><p>Evaluate the boolean expression <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyRate &gt; 20</code></span>. If it’s equal to <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span>, then it will return the string <span v-highlightjs><code class="go" v-pre style="display: inline">"Medium"</code></span>.</p></li>
<li><p>When the two previous expressions are false, the default block is executed, which returns the string <span v-highlightjs><code class="go" v-pre style="display: inline">"Low"</code></span></p></li>
</ol>
<p>Keep in mind that the return statement <strong>will terminate the function execution.</strong></p>
<div id="bits-of-advice" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Bits of Advice <a href="#bits-of-advice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ol type="1">
<li><p>Functions names should express clearly the operation that will be executed by the function</p>
<ol type="1">
<li><p>Avoid names with one letter</p></li>
<li><p>Use camel case</p></li>
<li><p>No more than two words</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">sendEmail</code></span> is good, <span v-highlightjs><code class="go" v-pre style="display: inline">sendEmailToTheOperationalTeam</code></span> is not so good</li>
</ol></li>
</ol></li>
<li><p>Parameters and results names should also convey information. Follow the same advice given before.</p></li>
<li><p>Avoid mentioning the type in the identifier.</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">descriptionString</code></span> is bad, <span v-highlightjs><code class="go" v-pre style="display: inline">description</code></span> is better.</li>
</ol></li>
<li><p>Choose the parameter types and the return types carefully. Well-chosen types improve the readability of your code.</p>
<ol type="1">
<li>Ex: <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span> is an unsigned integer (<span v-highlightjs><code class="go" v-pre style="display: inline">uint</code></span>), not an <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>. A negative number of nights does not exist (at least in our human dimension)</li>
</ol></li>
</ol>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="17.1"><span class="header-section-number">17.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>A function should have at least one parameter. True or False?</p></li>
<li><p>In a function, what is the purpose of a return statement?</p></li>
<li><p>What is the difference between the result(s) list and the parameter(s) list ?</p></li>
<li><p>Can we write Go programs without functions?</p></li>
<li><p>Fill the blank: To launch the execution of a function, I need to ______ it.</p></li>
<li><p>When I define a function, it will be launched at the beginning of my program. True or False?</p></li>
</ol>
<div id="answers-3" class="anchor"></div>
<h2 data-number="17.2"><span class="header-section-number">17.2</span> Answers <a href="#answers-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>A function should have at least one parameter. True or False?</p>
<ol type="1">
<li><p>False</p></li>
<li><p>A function can have 0 parameter.</p></li>
</ol></li>
<li><p>In a function, what is the purpose of a return statement?</p>
<ol type="1">
<li><p>A return statement stops the execution of the function.</p></li>
<li><p>It also optionally provide one or more result values</p></li>
</ol></li>
<li><p>What is the difference between the result(s) list and the parameter(s) list ?</p>
<ol type="1">
<li><p>They are both lists of identifiers and types</p></li>
<li><p>The result(s) list is the output of the function, what the function will return when it’s called</p></li>
<li><p>The parameter(s) list is the input of the function. The inner logic of the function will use input parameters</p></li>
</ol></li>
<li><p>Can we write Go programs without functions?</p>
<ol type="1">
<li><p>You can, but you will need at least a main function to start your program.</p></li>
<li><p>Functions are a great tool to build programs; you may see them as machines that can be called everywhere.</p></li>
</ol></li>
<li><p>Fill the blank: To launch a function, I need to ____ it.</p>
<ol type="1">
<li>call</li>
</ol></li>
<li><p>When I define a function, it will be launched at the beginning of my program. True or False?</p>
<ol type="1">
<li><p>This is false</p></li>
<li><p>The act of defining a function is different from calling a function.</p></li>
<li><p>A function will be executed when it’s called.</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Functions are convenient building blocks of programs</p></li>
<li><p>A function is like a “machine” :</p>
<ul>
<li><p>Designing / Building the machine =&gt; define the function</p></li>
<li><p>Start the machine =&gt; call the function</p></li>
</ul></li>
<li><p>Give your functions meaningful and short <strong>names</strong></p></li>
<li><p>Functions may have</p>
<ul>
<li><p>Parameters: input</p></li>
<li><p>Results: output</p></li>
</ul></li>
<li><p>The return statement terminates the execution of the function and optionally provide results to the caller<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>See https://golang.org/ref/spec#Return_statements<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap10Functions"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap9ControlStatements'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Control Statements</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap11PackagesAndImports'}">
									<p><u><small>Next</small></u></p>
									<p><small>Packages and imports</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Functions - Practical Go Lessons"
const description = "A function is a block of code invoked by a specific name. It may receive input values. It may also output values."

export default {
  name: "Chap10Functions",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
